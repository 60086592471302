import React from "react"

const Colophon = () => (
  <div>
    <p>Design by X and Y</p>
    <p>Development by X</p>
    <p>Audio and Jingle Samples by Evan Backer</p>
    <p>Font by X</p>
  </div>
)

export default Colophon
